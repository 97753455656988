<template>
	
		<div class="border py-1 px-2 rounded mr-2 position-relative d-flex align-items-center justify-content-center mb-3">
			<div class="d-flex align-items-center justify-content-center" v-if="type != 1">
				<!-- 颜色选择器 -->
				<el-color-picker size="mini" v-if="type===2" :value="item.color" @change="changeColor"></el-color-picker>
				<!-- 图片 -->
				<template v-else>
					<span class="btn btn-light border"  @click="chooseImage" v-if="!item.image">
						<i class="el-icon-plus"></i>
					</span>
					<img :src="item.image" style="width: 30px;height: 30px;cursor: pointer;" @click="chooseImage" v-else>
				</template>
				
			</div>
			<input :value="item.name" @input="changeInput" class="form-control text-center border-0" style="width: 85px;font-size: 15px;"/>
			<span class="btn btn-light p-0 position-absolute" style="line-height: 0;top: -8px;right: -8px;" @click="delSkuAttr({cardIndex:cardIndex,valIndex:index})">
				<i class="el-icon-circle-close p-0"></i>
			</span>
		</div>

</template>

<script>
	import { mapState,mapMutations } from "vuex"
	export default {
		inject:['app'],
		props: {
			type: {
				type: Number,
				default: 1
			},
			item: Object,
			index:{
				type: Number,
				default: 0
			},
			cardIndex:{
				type: Number,
				default: 0
			}
		},
		methods: {
			...mapMutations(['delSkuAttr','editSkuAttr']),
			vModel(key,val){
				this.editSkuAttr({
					cardIndex:this.cardIndex,
					valIndex:this.index,
					key,
					val
				})
			},
			changeInput(e){
				this.vModel('name',e.target.value)
			},
			chooseImage(){
				this.app.chooseImage((res)=>{
					this.vModel('image',res[0].url)
				},1)
			},
			changeColor(e){
				this.vModel('color',e)
			}
		}
	}
</script>

<style>
</style>
