<template>
	<div class="card mb-2" >
		<el-card>
			<div slot="header" class="clearfix">
			    <div class="flex align-center" style="line-height: 1.2;">
			    	规格项:
			    	<el-input placeholder="请输入规格" size="mini" style="width: 200px;" class="mx-2" :value="item.name" @input="vModel('name',index,$event)">
			    		<el-button slot="append" icon="el-icon-more-outline" @click="chooseSkus"></el-button>
			    	</el-input>
			    	<el-radio-group size="mini" style="margin-bottom: -10px;" :value="item.type" @input="vModel('type',index,$event)">
			    		<el-radio :label="1">无</el-radio>
			    		<el-radio :label="2">颜色</el-radio>
			    		<el-radio :label="3">图片</el-radio>
			    	</el-radio-group>
			    	<!-- 上移 -->
			    	<el-button icon="el-icon-top" size="mini" class="ml-auto" @click="sortCart('moveUp',index)" :disabled="index===0"></el-button>
			    	<!-- 下移 -->
			    	<el-button icon="el-icon-bottom" size="mini" @click="sortCart('moveDown',index)" :disabled="index+1===skuCardTotal"></el-button>
			    	<el-button type="text" size="mini" @click="delSkuCard(index)">删除</el-button>
			    </div>
			</div>
            <!-- 规格属性列表 -->
            <div class="flex align-center flex-wrap" style="height: auto;">
            	<skuAttrItem :type="item.type" :item="item2" :index="index2|strToNum" v-for="(item2,index2) in list" :key="index2" :cardIndex="index|strToNum" v-dragging="{ item: item2, list: list, group: `skuItem${index}` }"></skuAttrItem>
            </div>
            <div>
            	<el-button type="text" size="mini" icon="el-icon-plus" @click="addSkuAttrs(index)">增加规格属性</el-button>
            </div>

		</el-card>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import { mapState,mapMutations } from "vuex"
	import skuAttrItem from "./sku_attr_item.vue"
	export default {
		inject:['app'],
		mixins:[common],
		components:{ skuAttrItem },
		props: {
			item: Object,
			index:Number,
			skuCardTotal:Number
		},
		data() {
			return {
				list: this.item.list,
				autoRequest: false,
			}
		},
		mounted () {
		    this.$dragging.$on('dragged', ({ value }) => {
			  // console.log('拖拽过程')
		      // console.log(value.item)
		      // console.log(value.list)
		      // console.log(value.group)
		    })
		    this.$dragging.$on('dragend', (e) => {
		        // console.log('拖拽结束')
				if(e.group == 'skuItem'+this.index){
					this.sortSkuAttr({
						index:this.index,
						val:this.list
					})
				}
		    })
			//监听list值变化
			this.$watch('item.list',(newVal,oldVal)=>{
				this.list = newVal
			})
		},
		methods: {
			...mapMutations(['delSkuCard','editSkuCard','sortSkuCard','addSkuAttrs','sortSkuAttr']),
            vModel(key,index,val){
            	this.editSkuCard({key,index,val})
            },
			sortCart(action,index){
				this.sortSkuCard({action,index})
			},
			chooseSkus(){
				this.app.chooseSkus((res)=>{
					this.vModel('name',this.index,res.name)
					this.vModel('type',this.index,res.type)
					this.vModel('list',this.index,res.list)
					this.list = res.list
				})
			}
		}
	}
</script>

<style scoped>
/deep/ .el-card.is-always-shadow{
	box-shadow:none;
}
</style>
