<template>
	<table class="table table-sm table-bordered">
	  <thead>
	    <tr>
	      <th scope="col" class="text-center" 
		  :rowspan="th.rowspan" 
		  :colspan="th.colspan" 
		  style="vertical-align: middle;"
		  v-for="(th,thi) in tableThs" 
		  :key="thi">{{th.name}}</th>
	    </tr>
	   <tr>
		 <th scope="col" class="text-center"
		 style="vertical-align: middle;"
		 rowspan="1" colspan="1"
		 v-for="(th,thi) in skuLabels"
		 :key="thi">{{th.name}}</th>
	   </tr>
	  </thead>
	  <tbody>
	    <tr v-for="(item,index) in list" :key="index">
	      <th scope="row" class="text-center" width="100" v-for="(sku,skuI) in item.skus" :key="skuI">{{sku.name}}</th>
		  <td class="text-center" width="100"><el-radio v-model="default_label" :label="item.label" @input="vModel('is_default',$event)">是否默认</el-radio></td>
	      <td class="text-center" width="100">
			  <span class="btn btn-light border"  @click="chooseImage(item)" v-if="!item.image">
			  	<i class="el-icon-plus"></i>
			  </span>
			  <img :src="item.url" style="width: 30px;height: 30px;cursor: pointer;" @click="chooseImage(item)" v-else>
		  </td>
	      <td class="text-center" width="100"><input type="number" v-model="item.price" class="form-control text-center"/></td>
		  <td class="text-center" width="100"><input type="number" v-model="item.mktprice" class="form-control text-center"/></td>
	      <td class="text-center" width="100"><input type="number" v-model="item.stock" class="form-control text-center"/></td>
	    </tr>
	  </tbody>
	</table>
</template>

<script>
	import { mapGetters,mapState,mapMutations } from "vuex"
	export default {
		inject:['app'],
		props:{
			sku_list:Array,
		},
		data() {
			return {
				list: [],
				default_label:1,
			}
		},
		computed:{
			...mapGetters(['tableThs','goodsData','skuLabels']),
			...mapState({
				sku_card:state=>state.goods.sku_card,
				is_default:state=>state.goods.is_default,
			}),
		},
		mounted() {
           this.list = this.goodsData
		   this.default_label = this.is_default
		},
		watch:{
			goodsData(newValue,oldValue){
				this.list = newValue
			},
			sku_list(newVal,oldVal){
				this.list = newVal
			},
			// sku_list: {
			//   deep: true,
			//   handler(newVal,oldVal) {
			// 	 this.list = newVal
			//   }
			// }
		},
		methods:{
			...mapMutations(['eState']),
			vModel(key,val){
				this.eState({key,val})
			},
			chooseImage(item){
				this.app.chooseImage((res)=>{
					item.image = res[0].id
					item.url = res[0].url
				},1)
			},
		}
	}
</script>

<style>
</style>
